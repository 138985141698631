import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Section, Carousel, EventCardList } from 'components';
import { Cafesori, CafesoriEvent } from 'containers';

const CafesoriPage = () => {
  const data = useStaticQuery(query);
  const imgList = data.allStrapiCafesoriPage.nodes[0].carousel_img;
  const pageDescription = data.strapiCafesoriPage.page_description;
  return (
    <Layout>
      <Carousel
        imgList={imgList}
        pageTitle={'카페소리'}
        pageDescription={pageDescription}
      />
      {data.cafesori_events.nodes[0] ? (
        <Section title='공연 이벤트'>
          <EventCardList events={data.cafesori_events} />
        </Section>
      ) : null}
      <Cafesori
        hours={data.strapiCafesoriPage.hours}
        address={data.strapiCafesoriPage.address}
        menu={
          data.strapiCafesoriPage.menu.localFile.childImageSharp.gatsbyImageData
        }
        cafeImg={
          data.strapiCafesoriPage.cafeImg.localFile.childImageSharp
            .gatsbyImageData
        }
        equipImg1={
          data.strapiCafesoriPage.equipImg1.localFile.childImageSharp
            .gatsbyImageData
        }
        equipImg2={
          data.strapiCafesoriPage.equipImg2.localFile.childImageSharp
            .gatsbyImageData
        }
      />
    </Layout>
  );
};

const query = graphql`
  query {
    allStrapiCafesoriPage {
      nodes {
        carousel_img {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
    strapiCafesoriPage {
      id
      address
      hours
      page_description
      cafeImg {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      menu {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      equipImg1 {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      equipImg2 {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    cafesori_events: allStrapiEvents(
      filter: {
        type: { eq: "event" }
        status: { eq: "now" }
        location: { title: { eq: "카페 소리" } }
        cafe_sori: { eq: true }
      }
      limit: 2
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        title
        description
        type
        short_description
        slug
        date
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 700
                aspectRatio: 0.72
                transformOptions: { fit: FILL }
              )
            }
          }
        }
        location {
          title
          slug
        }
      }
    }
  }
`;
export default CafesoriPage;
